import { onUnmounted, onMounted } from 'vue';

import { headerComponents, showLogo } from '@mfe/core-header';
import { Unsub } from '@mfl/framework';

import LayoutEditorHeader from './layout-editor-header.vue';

export function initLayoutEditorHeader(save: () => void) {
  let removeHeaderWidget: Unsub | undefined;

  onMounted(() => {
    removeHeaderWidget = headerComponents.register({
      key: 'layout-editor-key',
      component: LayoutEditorHeader,
      events: {
        save: () => {
          save();
        },
      },
    });
    showLogo(false);
  });

  onUnmounted(() => {
    if (removeHeaderWidget) removeHeaderWidget();
    showLogo(true);
  });
}
