<script setup lang="ts">
import { ref } from 'vue';

import {
  WsTooltip,
  WsSelect,
  WsSelectOption,
  WsMenu,
} from '@mfl/common-components';

import IconToggle from './components/icon-toggle.vue';
import { strings } from './text-typography.strings';
import { CustomComponentModels } from './text-typography.types';
import {
  FONT_FAMILIES,
  FONT_SIZES,
  TYPOGRAPHY_DEFAULTS,
  BUTTONS,
} from './text-typography.consts';

// Typography Models
const color = defineModel<string>('color', {
  default: TYPOGRAPHY_DEFAULTS.color,
});
const bold = defineModel<boolean>('bold', {
  default: TYPOGRAPHY_DEFAULTS.bold,
});
const italic = defineModel<boolean>('italic', {
  default: TYPOGRAPHY_DEFAULTS.italic,
});
const underline = defineModel<boolean>('underline', {
  default: TYPOGRAPHY_DEFAULTS.underline,
});
const uppercase = defineModel<boolean>('uppercase', {
  default: TYPOGRAPHY_DEFAULTS.uppercase,
});
const alignment = defineModel<string>('alignment', {
  default: TYPOGRAPHY_DEFAULTS.alignment,
});
const lineHeight = defineModel<string | number>('lineHeight', {
  default: TYPOGRAPHY_DEFAULTS.lineHeight,
});
const letterSpacing = defineModel<string | number>('letterSpacing', {
  default: TYPOGRAPHY_DEFAULTS.letterSpacing,
});
const fontFamily = defineModel<string>('fontFamily', {
  default: TYPOGRAPHY_DEFAULTS.fontFamily,
});
const fontSize = defineModel<number>('fontSize', {
  default: TYPOGRAPHY_DEFAULTS.fontSize,
});

defineProps<{
  colorsInUse?: string[];
}>();

const lineHeightInPercents = ref<number>(
  Math.round((Number(lineHeight.value) * 100) / fontSize.value / 5) * 5
);

const buttonsModel = ref({
  color,
  bold,
  italic,
  underline,
  uppercase,
  alignment,
  lineHeightInPercents,
  letterSpacing,
});

const customComponentsModels = ref<CustomComponentModels>({
  color: buttonsModel.value.color,
  lineHeightInPercents: lineHeightInPercents.value,
  letterSpacing: buttonsModel.value.letterSpacing,
});

function fontSizeUpdate(value: number) {
  lineHeight.value = `${Math.round((Number(lineHeightInPercents.value) / 100) * value)}px`;
}

function iconButtonUpdate(property: string, value: unknown) {
  if (property === 'alignment') {
    BUTTONS.value.find((btn) => btn.property === 'alignment')!.icon =
      `fa-regular fa-align-${value}`;
  }
}

function updateWithCustomComponent(
  property: string,
  value: string | number | boolean
) {
  const button = BUTTONS.value.find((btn) => btn.property === property);
  if (button) {
    if (property === 'lineHeightInPercents') {
      lineHeight.value = `${Math.round(
        (Number(value) / 100) * Number(fontSize.value)
      )}px`;
    }

    (buttonsModel.value as Record<string, string | number | boolean>)[
      property
    ] = value;
  }
}
</script>

<template>
  <div class="text-typography">
    <div class="text-sm text-bold">{{ strings.title }}</div>
    <div class="text-typography__selects">
      <!-- TODO: improve selects to use with v-for -->
      <WsSelect
        v-model="fontFamily"
        size="xs"
        aid="text_typography_font_family"
        :option-label="(value) => value"
        :option-key="(value) => value"
        class="flex-1"
      >
        <WsSelectOption v-for="font in FONT_FAMILIES" :key="font" :value="font">
          {{ font }}
        </WsSelectOption>
      </WsSelect>

      <WsSelect
        v-model="fontSize"
        size="xs"
        aid="text_typography_font_size"
        :option-label="(value) => value.toString()"
        :option-key="(value) => value"
        class="flex-1"
        @update:model-value="fontSizeUpdate($event as number)"
      >
        <WsSelectOption v-for="size in FONT_SIZES" :key="size" :value="size">
          {{ size }}
        </WsSelectOption>
      </WsSelect>
    </div>

    <div class="text-typography__buttons">
      <IconToggle
        v-for="btn in BUTTONS"
        :key="btn.property"
        v-model="buttonsModel[btn.property as keyof typeof buttonsModel]"
        :property="btn.property"
        :icon="btn.icon"
        :values="btn.values"
        :type="btn.type"
        :aid="`TEXT_TYPOGRAPHY_${btn.property}`"
        @update:model-value="iconButtonUpdate(btn.property, $event)"
      >
        <template v-if="btn.iconUrl" #icon>
          <img :src="btn.iconUrl" alt="icon" style="justify-self: center" />
        </template>

        <WsTooltip
          v-if="btn.tooltip"
          :aid="`TEXT_TYPOGRAPHY_${btn.property}_TOOLTIP`"
        >
          {{ btn.tooltip }}
        </WsTooltip>
        <template v-if="btn.component">
          <!--
            TODO: add new menu options to prevent auto closed when clicking on the
            color picker after it would be developed
          -->
          <WsMenu aid="TEXT_TYPOGRAPHY_COLOR_PICKER" @click.stop>
            <component
              :is="btn.component"
              :key="btn.property"
              v-model="
                customComponentsModels[
                  btn.property as keyof typeof customComponentsModels
                ]
              "
              :aid="`TEXT_TYPOGRAPHY_${btn.property.toUpperCase()}_COMPONENT`"
              v-bind="btn.attributes"
              @update:model-value="
                updateWithCustomComponent(btn.property, $event)
              "
            />
          </WsMenu>
        </template>
      </IconToggle>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.text-typography {
  &__selects {
    display: flex;
    gap: 14px;
    justify-content: space-between;
    margin-block: 14px;
  }

  &__buttons {
    display: flex;
    gap: 14px;
    justify-content: center;
  }
}
</style>

<style lang="scss">
// cancel q-list padding (showing double shadow)
[aid='TEXT_TYPOGRAPHY_COLOR_COMPONENT'] {
  margin: -14px 0;
}
</style>
