import {
  DROP_BOTTOM,
  DROP_RIGHT,
  DROP_LEFT,
  DROP_TOP,
} from './layout-preview-consts';
import { generateRandomUUID } from './drag-utils';
import { signatureStyle, signatureData } from '../../shared/reactives';
import { ElementTag } from '../../shared/types';

export function removeHoverBorders(target: HTMLElement) {
  target.classList.remove(DROP_LEFT, DROP_RIGHT);
  if (target.parentElement) {
    target.parentElement.classList.remove(DROP_TOP, DROP_BOTTOM);
  }
}

export function addElementBorder(e: HTMLElement, directionClass: string) {
  // Return early if element already has right border
  if (e.classList.contains(DROP_RIGHT)) return;
  const isHorizontalBorder =
    directionClass === DROP_LEFT || directionClass === DROP_RIGHT;
  const isVerticalBorder =
    directionClass === DROP_TOP || directionClass === DROP_BOTTOM;
  const hasHorizontalBorder =
    e.classList.contains(DROP_LEFT) || e.classList.contains(DROP_RIGHT);

  // Remove vertical borders from parent if adding horizontal border
  if (isHorizontalBorder && e.parentElement) {
    e.parentElement.classList.remove(DROP_TOP, DROP_BOTTOM);
  }

  // Handle vertical borders
  if (isVerticalBorder) {
    if (hasHorizontalBorder) {
      // Don't add vertical border if element already has horizontal border
      e.parentElement?.classList.remove(DROP_TOP, DROP_BOTTOM);
      return;
    }
    // Add vertical border to parent
    e.parentElement?.classList.add(directionClass);
    return;
  }

  // Add horizontal border to element
  e.classList.add(directionClass);
}

/**
 * function that sets current elements attributes to its parent
 */
export function addChildAttributes(element: HTMLElement) {
  const parentElement = element.parentElement;
  if (!parentElement) return;

  const elementStyleId = parentElement.getAttribute('element-style-id');
  const elementDataId = parentElement.getAttribute('element-data-id');

  if (!elementStyleId) {
    const styleUUID = generateRandomUUID('STYLE');
    parentElement.setAttribute('element-style-id', styleUUID);

    const type = parentElement.getAttribute('element-ws-tag') ?? '';
    // TODO: load the styles from defaults
    const defaultStyle =
      type === ElementTag.IMAGE.toString()
        ? { width: '50px' }
        : {
            'font-size': '14px',
            'font-family': 'Arial',
            'line-height': '17px',
          };

    signatureStyle[styleUUID] = defaultStyle;
  }

  if (!elementDataId) {
    const dataUUID = generateRandomUUID('DATA');
    parentElement.setAttribute('element-data-id', dataUUID);

    const type = parentElement.getAttribute('element-ws-tag') ?? '';
    const defaultData =
      type === ElementTag.IMAGE.toString()
        ? {
            source:
              'https://img.freepik.com/premium-vector/avatar-profile-icon-flat-style-male-user-profile-vector-illustration-isolated-background-man-profile-sign-business-concept_157943-38764.jpg?semt=ais_hybrid',
          }
        : { value: 'Default Text' };

    signatureData[dataUUID] = defaultData;
  }
}
