import { reactive } from 'vue';

import { EditorViewMode, Signature } from '../layout-editor-types';

export const focusedElement = reactive<{ value: HTMLElement | null }>({
  value: null,
});

export const currentTab = reactive<{ value: EditorViewMode }>({
  value: EditorViewMode.ELEMENTS,
});

export const signatureStyle = reactive<Record<string, Record<string, unknown>>>(
  {}
);
export const signatureData = reactive<Record<string, Record<string, unknown>>>(
  {}
);

export const selectedSignature = reactive<Signature>({
  id: '',
  name: '',
});
