import { ref, shallowRef } from 'vue';
import { WsColorPicker } from '@mfl/common-components';

import { strings } from './text-typography.strings';
import colorIcon from './color-icon.svg';
import sliderControl from './components/slider-control.vue';
import { Button } from './text-typography.types';
import { IconToggleType } from './components/icon-toggle.vue';

export const FONT_FAMILIES = [
  'Arial',
  'Verdana',
  'Trebuchet MS',
  'Georgia',
  'Palatino',
  'Lucida Sans',
  'Times New Roman',
  'Courier New',
] as const;

export const FONT_SIZES = Array.from({ length: 38 }, (_, i) => i + 5);

export const MIN_FONT_SIZE = 5;
export const MAX_FONT_SIZE = 42;

export const MIN_LINE_HEIGHT = 75;
export const MAX_LINE_HEIGHT = 400;

export const MIN_LETTER_SPACING = 0;
export const MAX_LETTER_SPACING = 10;

export const TYPOGRAPHY_DEFAULTS = {
  color: 'rgb(var(--ws-color-text-primary))',
  fontSize: 14,
  lineHeight: 17,
  letterSpacing: 2,
  fontFamily: 'Arial',
  fontWeight: 'normal',
  alignment: 'left',
  bold: false,
  italic: false,
  underline: false,
  uppercase: false,
};

export const BUTTONS = ref<Button[]>([
  {
    property: 'color',
    iconUrl: colorIcon,
    component: shallowRef(WsColorPicker),
    tooltip: strings.tooltips.color,
    type: IconToggleType.CUSTOM,
    attributes: {
      // TODO: provide colorInUse to the picker
      colorInUse: [],
    },
  },
  {
    property: 'bold',
    icon: 'fa-kit fa-bold',
    tooltip: strings.tooltips.bold,
    type: IconToggleType.FLAG,
  },
  {
    property: 'italic',
    icon: 'fa-regular fa-italic',
    tooltip: strings.tooltips.italic,
    type: IconToggleType.FLAG,
  },
  {
    property: 'underline',
    icon: 'fa-kit fa-underline',
    tooltip: strings.tooltips.underline,
    type: IconToggleType.FLAG,
  },
  {
    property: 'uppercase',
    icon: 'fa-kit fa-uppercase',
    tooltip: strings.tooltips.uppercase,
    type: IconToggleType.FLAG,
  },
  {
    property: 'alignment',
    icon: `fa-regular fa-align-${TYPOGRAPHY_DEFAULTS.alignment}`,
    values: ['left', 'center', 'right'],
    tooltip: strings.tooltips.alignment,
    type: IconToggleType.LOOP,
  },
  {
    property: 'lineHeightInPercents',
    icon: 'fa-kit fa-lineheight',
    tooltip: strings.tooltips.lineHeight,
    component: shallowRef(sliderControl),
    attributes: {
      suffix: '%',
      min: MIN_LINE_HEIGHT,
      max: MAX_LINE_HEIGHT,
      step: 5,
      title: strings.sliderTitles.lineHeight,
    },
    type: IconToggleType.CUSTOM,
  },
  {
    property: 'letterSpacing',
    icon: 'fa-kit fa-letter-spacing',
    tooltip: strings.tooltips.letterSpacing,
    component: shallowRef(sliderControl),
    attributes: {
      suffix: 'px',
      min: MIN_LETTER_SPACING,
      max: MAX_LETTER_SPACING,
      step: 1,
      title: strings.sliderTitles.letterSpacing,
    },
    type: IconToggleType.CUSTOM,
  },
]);
