<script setup lang="ts">
import { WsButton } from '@mfl/common-components';

const emits = defineEmits(['save']);

async function save() {
  emits('save');
}
</script>

<template>
  <div class="layout-editor-header-widget">
    <WsButton
      aid="LAYOUTEDITOR_HEADER_BACK"
      label="Save"
      type="button"
      @click="save"
    />
  </div>
</template>

<style scoped>
.layout-editor-header-widget {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
</style>
