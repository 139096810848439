<script setup lang="ts">
import { onBeforeMount, onUnmounted } from 'vue';

import { navigationBarMode, NavigationBarMode } from '@mfe/core-navigation';
import { ToastStatus, toast } from '@mfl/common-components';

import SidePanel from './components/side-panel/side-panel.vue';
import LayoutPreview from './components/layout-preview/layout-preview.vue';
import { selectedSignature } from './shared/reactives';
import { openSignatureNameDialog } from './components/signature-name-dialog/signature-name-dialog';
import { saveSignature } from './shared/db';
import { initLayoutEditorHeader } from './components/header/layout-editor-header';

let oldMode: NavigationBarMode | undefined;
initLayoutEditorHeader(save);

onBeforeMount(() => {
  oldMode = navigationBarMode.value;
  navigationBarMode.value = NavigationBarMode.Hidden;
});

onUnmounted(() => {
  if (oldMode) navigationBarMode.value = oldMode;
});

async function save() {
  let signatureName = '';
  if (!selectedSignature.id) {
    const dialogRes = await openSignatureNameDialog('');
    signatureName = dialogRes.name;
  } else {
    signatureName = selectedSignature.name;
  }

  if (signatureName !== '') {
    const res = await saveSignature(signatureName, selectedSignature.id);
    if (res === ToastStatus.Completed) {
      toast({
        message: `${signatureName} was saved successfully`,
        status: ToastStatus.Completed,
        aid: 'LAYOUT_EDITOR_SAVE_SIGNATURE_SUCCESS',
      });
    } else {
      toast({
        message: 'Error while saving signature',
        status: ToastStatus.Error,
        aid: 'LAYOUT_EDITOR_SAVE_SIGNATURE_ERROR',
      });
    }
  }
}

// Expose save function for testing
defineExpose({
  save,
});
</script>

<template>
  <div class="layout-editor">
    <!-- Side panel with two draggable source items -->
    <side-panel />
    <layout-preview />
  </div>
</template>

<style scoped lang="scss">
.layout-editor {
  display: flex;
  height: 100vh;
}
</style>
