export const strings = {
  title: 'Font Style',
  tooltips: {
    bold: 'Bold',
    italic: 'Italic',
    underline: 'Underline',
    uppercase: 'Uppercase',
    alignment: 'Alignment',
    lineHeight: 'Line Height',
    letterSpacing: 'Letter Spacing',
    color: 'Color',
  },
  sliderTitles: {
    lineHeight: 'Line Height',
    letterSpacing: 'Letter Spacing',
  },
};
