<script setup lang="ts">
import { computed } from 'vue';

import { WsInput, WsSlider } from '@mfl/common-components';

import { ElementTag } from '../../shared/types';
import { elementBoxes } from '../side-panel/side-panel-utils';
import { removeElementFocus } from '../layout-preview/element-events';
import strings from './element-editor.strings';
import {
  signatureData,
  signatureStyle,
  focusedElement,
} from '../../shared/reactives';
import TextTypography from '../style-controls/text-typography/text-typography.vue';

import {
  fontSize,
  contentValue,
  fontWeight,
  lineHeight,
  color,
  fontFamily,
  fontStyle,
  textDecoration,
  letterCase,
  letterSpacing,
} from './elements/text';

const IMAGE_HEIGHT = {
  MIN: 5,
  MAX: 500,
  DEFAULT: 50,
} as const;

const IMAGE_WIDTH = {
  MIN: 5,
  MAX: 500,
  DEFAULT: 50,
} as const;

const selectedElementType = computed<ElementTag | null>(() => {
  if (!focusedElement.value?.parentElement) return null;
  return (
    (focusedElement.value?.parentElement?.getAttribute(
      'element-ws-tag'
    ) as unknown as ElementTag) || null
  );
});

const elementName = computed(() => {
  return elementBoxes.find((box) => box.tag === selectedElementType.value)
    ?.label;
});

const closeEditElement = () => {
  if (focusedElement.value) {
    removeElementFocus(focusedElement.value);
  }
};

const elementStyleId = computed(() => {
  const parent = focusedElement.value?.parentElement;
  if (!parent) return null;
  return parent.getAttribute('element-style-id');
});

const elementDataId = computed(() => {
  const parent = focusedElement.value?.parentElement;
  if (!parent) return null;
  return parent.getAttribute('element-data-id');
});

const imageSourceValue = computed({
  get: () => {
    const dataId = elementDataId.value;
    if (!dataId || !signatureData[dataId]) {
      return '';
    }
    return (signatureData[dataId] as { source?: string }).source || '';
  },
  set: (source: string) => {
    const dataId = elementDataId.value;
    if (dataId) {
      signatureData[dataId] = {
        ...(signatureData[dataId] as Record<string, string>),
        source,
      };
    }
  },
});

const imageHeight = computed({
  get: () => {
    const styleId = elementStyleId.value;
    if (!styleId) return IMAGE_HEIGHT.DEFAULT;

    const elementStyle = signatureStyle[styleId] as Record<string, string>;
    if (!elementStyle?.height) return IMAGE_HEIGHT.DEFAULT;

    return parseInt(elementStyle.height.replace('px', ''));
  },
  set: (value: number) => {
    const styleId = elementStyleId.value;
    if (!styleId) return;

    signatureStyle[styleId] = {
      ...(signatureStyle[styleId] as Record<string, string>),
      height: `${value}px`,
    };
  },
});

const imageWidth = computed({
  get: () => {
    const styleId = elementStyleId.value;
    if (!styleId) return IMAGE_WIDTH.DEFAULT;

    const elementStyle = signatureStyle[styleId] as Record<string, string>;
    if (!elementStyle?.width) return IMAGE_WIDTH.DEFAULT;

    return parseInt(elementStyle.width.replace('px', ''));
  },
  set: (value: number) => {
    const styleId = elementStyleId.value;
    if (!styleId) return;

    signatureStyle[styleId] = {
      ...(signatureStyle[styleId] as Record<string, string>),
      width: `${value}px`,
    };
  },
});
</script>

<template>
  <div class="element-editor">
    <div class="element-editor-header">
      <span class="element-editor-header-title">{{ elementName }}</span>
      <span class="element-editor-header-close" @click="closeEditElement">
        <span class="fa-close fa-light"></span>
      </span>
    </div>

    <div class="element-editor-content">
      <!-- Content Tab -->
      <template v-if="selectedElementType === ElementTag.TEXT">
        <span class="element-editor-content-header">{{ strings.content }}</span>
        <ws-input v-model="contentValue" :aid="`TEXT_VALUE`" />
      </template>

      <template v-if="selectedElementType === ElementTag.IMAGE">
        <span class="element-editor-content-header">Image Source</span>
        <ws-input v-model="imageSourceValue" :aid="`IMAGE_SOURCE`" />
      </template>
    </div>

    <div class="element-editor-content">
      <!-- Design Tab -->
      <template v-if="selectedElementType === ElementTag.TEXT">
        <TextTypography
          v-model:font-family="fontFamily"
          v-model:font-size="fontSize"
          v-model:color="color"
          v-model:bold="fontWeight"
          v-model:italic="fontStyle"
          v-model:underline="textDecoration"
          v-model:uppercase="letterCase"
          v-model:line-height="lineHeight"
          v-model:letter-spacing="letterSpacing"
        />
      </template>

      <template v-if="selectedElementType === ElementTag.IMAGE">
        <span class="element-editor-content-header">Image Height</span>
        <ws-slider
          v-model="imageHeight"
          label
          :aid="`IMAGE_HEIGHT`"
          :min="IMAGE_HEIGHT.MIN"
          :max="IMAGE_HEIGHT.MAX"
        />
        <span class="element-editor-content-header">Image Width</span>
        <ws-slider
          v-model="imageWidth"
          label
          :aid="`IMAGE_WIDTH`"
          :min="IMAGE_WIDTH.MIN"
          :max="IMAGE_WIDTH.MAX"
        />
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.element-editor {
  padding: 14px 0;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 24px 20px;

    &-title {
      font-size: 16px;
      font-weight: 700;
      color: rgb(var(--color-gray-500));
    }

    &-close {
      cursor: pointer;
      color: rgb(var(--color-gray-400));
    }
  }

  &-content {
    padding: 20px 24px;
    border-top: 1px solid rgb(var(--color-gray-100));

    &-header {
      font-size: 14px;
      font-weight: 700;
      color: rgb(var(--color-gray-500));
      margin-bottom: 12px;
    }
  }
}
</style>
