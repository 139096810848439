<script lang="ts">
export enum IconToggleType {
  FLAG = 'flag',
  LOOP = 'loop',
  CUSTOM = 'custom',
}
</script>

<script setup lang="ts">
const modelValue = defineModel<unknown>({ required: true });

const props = withDefaults(
  defineProps<{
    property: string;
    icon?: string;
    values?: string[] | number[];
    /*
      flag - boolean
      loop - iterate over the values array
      custom - kind of readonly model,
              the value will be changed via custom component rendered in the default slot
              and the component it self won't handle the value change
    */
    type?: IconToggleType;
    aid: string;
  }>(),
  {
    type: IconToggleType.FLAG,
  }
);

const emit = defineEmits<{
  (e: 'click'): void;
}>();

const handleClick = () => {
  emit('click');
  switch (props.type) {
    case IconToggleType.FLAG:
      modelValue.value = !modelValue.value;
      break;
    case IconToggleType.LOOP:
      {
        const values = props.values ?? [];
        const currentIndex = values.indexOf(modelValue.value as never);
        const nextIndex = (currentIndex + 1) % values.length;
        modelValue.value = values[nextIndex] ?? '';
      }
      break;
  }
};
</script>

<template>
  <button
    :aid="props.aid"
    :style="
      modelValue && typeof modelValue === 'boolean'
        ? '--button-bg: var(--color-primary-300); --button-color: var(--color-primary);'
        : ''
    "
    @click="handleClick"
  >
    <slot name="icon">
      <i :class="props.icon" />
    </slot>
    <slot />
  </button>
</template>

<style lang="scss" scoped>
button {
  --button-bg: var(--color-white);
  --button-color: var(--color-gray-500);

  width: 31px;
  height: 31px;
  border: 1px solid rgb(var(--color-gray-200));
  color: rgb(var(--button-color));
  background-color: rgb(var(--button-bg));
  border-radius: 4px;
  cursor: pointer;

  i {
    font-size: 13px;
  }
}
</style>
