import { computed } from 'vue';
import {
  signatureStyle,
  signatureData,
  focusedElement,
} from '../../../shared/reactives';

const elementStyleId = computed(() => {
  const parent = focusedElement.value?.parentElement;
  if (!parent) return null;
  return parent.getAttribute('element-style-id');
});

const elementDataId = computed(() => {
  const parent = focusedElement.value?.parentElement;
  if (!parent) return null;
  return parent.getAttribute('element-data-id');
});

export const contentValue = computed({
  get: () => {
    const dataId = elementDataId.value;
    if (!dataId || !signatureData[dataId]) {
      return '';
    }
    return (signatureData[dataId] as { value?: string }).value || '';
  },
  set: (value: string) => {
    const dataId = elementDataId.value;
    if (dataId) {
      signatureData[dataId] = {
        ...(signatureData[dataId] as Record<string, string>),
        value,
      };
    }
  },
});

const FONT_SIZE = {
  MIN: 5,
  MAX: 42,
  DEFAULT: 14,
} as const;

const LINE_HEIGHT = {
  MIN: 1,
  MAX: 2.5,
  DEFAULT: 16,
} as const;

const LETTER_SPACING = {
  MIN: 1,
  MAX: 10,
  DEFAULT: 0,
} as const;

function getStyleValue<T = string>(
  styleId: string | undefined,
  property: string,
  defaultValue?: T
) {
  if (!styleId || !signatureStyle[styleId]) return defaultValue ?? '';
  const elementStyle = signatureStyle[styleId] as Record<string, string>;
  return elementStyle[property] ?? (defaultValue as T);
}

function setStyleValue(
  styleId: string | undefined,
  property: string,
  value?: string,
  defaultValue?: string
) {
  if (!styleId) return;

  if (value === defaultValue) {
    const currentStyle = signatureStyle[styleId] as Record<string, string>;
    delete currentStyle[property];
    signatureStyle[styleId] = { ...currentStyle };
    return;
  }

  signatureStyle[styleId] = {
    ...(signatureStyle[styleId] as Record<string, string>),
    [property]: value,
  };
}

export const fontFamily = computed({
  get: () => {
    const styleId = elementStyleId.value;
    return getStyleValue(styleId as string, 'font-family');
  },
  set: (value: string) => {
    const styleId = elementStyleId.value;
    setStyleValue(styleId as string, 'font-family', value);
  },
});

export const fontSize = computed({
  get: () => {
    const styleId = elementStyleId.value;
    const fontSize = getStyleValue(
      styleId as string,
      'font-size',
      `${FONT_SIZE.DEFAULT}px`
    );
    return parseInt(fontSize.replace('px', ''));
  },
  set: (value: number) => {
    const styleId = elementStyleId.value;
    setStyleValue(styleId as string, 'font-size', `${value}px`);
  },
});

export const color = computed({
  get: () => {
    const styleId = elementStyleId.value;
    return getStyleValue(styleId as string, 'color', '');
  },
  set: (value: string) => {
    const styleId = elementStyleId.value;
    setStyleValue(styleId as string, 'color', value);
  },
});

export const fontWeight = computed({
  get: () => {
    const styleId = elementStyleId.value;
    return getStyleValue<boolean>(
      styleId as string,
      'font-weight',
      false
    ) as boolean;
  },
  set: (value: boolean) => {
    const styleId = elementStyleId.value;
    setStyleValue(
      styleId as string,
      'font-weight',
      value ? 'bold' : 'normal',
      'normal'
    );
  },
});

export const fontStyle = computed({
  get: () => {
    const styleId = elementStyleId.value;
    return getStyleValue<boolean>(styleId as string, 'font-style') as boolean;
  },
  set: (value: boolean) => {
    const styleId = elementStyleId.value;
    setStyleValue(
      styleId as string,
      'font-style',
      value ? 'italic' : 'none',
      'none'
    );
  },
});

export const textDecoration = computed({
  get: () => {
    const styleId = elementStyleId.value;
    return getStyleValue<boolean>(
      styleId as string,
      'text-decoration'
    ) as boolean;
  },
  set: (value: boolean) => {
    const styleId = elementStyleId.value;
    setStyleValue(
      styleId as string,
      'text-decoration',
      value ? 'underline' : 'none',
      'none'
    );
  },
});

export const letterCase = computed({
  get: () => {
    const styleId = elementStyleId.value;
    return getStyleValue<boolean>(styleId as string, 'letter-case') as boolean;
  },
  set: (value: boolean) => {
    const styleId = elementStyleId.value;
    setStyleValue(
      styleId as string,
      'letter-case',
      value ? 'uppercase' : 'none',
      'none'
    );
  },
});

// export const textAlign = computed({
//   get: () => {
//     const styleId = elementStyleId.value;
//     return getStyleValue<string>(styleId as string, 'text-align', 'left') as string;
//   },
//   set: (value: string) => {
//     const styleId = elementStyleId.value;
//     setStyleValue(styleId as string, 'text-align', value, 'left');
//   },
// });

export const lineHeight = computed({
  get: () => {
    const styleId = elementStyleId.value;
    const lineHeight = getStyleValue(
      styleId as string,
      'line-height',
      LINE_HEIGHT.DEFAULT
    );

    return parseInt(lineHeight?.toString().replace('px', ''));
  },
  set: (value: number) => {
    const styleId = elementStyleId.value;
    setStyleValue(
      styleId as string,
      'line-height',
      value ? `${value}px` : `${LINE_HEIGHT.DEFAULT}px`
    );
  },
});

export const letterSpacing = computed({
  get: () => {
    const styleId = elementStyleId.value;
    const letterSpacing = getStyleValue(
      styleId as string,
      'letter-spacing',
      `${LETTER_SPACING.DEFAULT}px`
    );
    return parseInt(letterSpacing.replace('px', ''));
  },
  set: (value: number) => {
    const styleId = elementStyleId.value;
    setStyleValue(
      styleId as string,
      'letter-spacing',
      value ? `${value}px` : `${LETTER_SPACING.DEFAULT}px`
    );
  },
});
