<script setup lang="ts">
import { WsSlider } from '@mfl/common-components';

defineProps<{
  title: string;
  aid: string;
  min: number;
  max: number;
  step?: number;
  modelValue: number;
  suffix?: string;
}>();

const emit = defineEmits<{
  'update:model-value': [number];
}>();
</script>

<template>
  <div class="slider-control" style="width: 190px; padding: 14px">
    <div class="text-md text-bold">{{ title }}</div>
    <WsSlider
      :model-value="modelValue"
      :aid="aid"
      :min="min"
      :max="max"
      :suffix="suffix"
      :step="step"
      type="edit"
      :label="false"
      @update:model-value="emit('update:model-value', $event)"
    />
  </div>
</template>
