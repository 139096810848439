import { handleDragStart } from '../../shared/event-handlers';
import { CallBack } from '../../shared/types';

export function generateRandomUUID(prefix: string = 'WS') {
  return `${prefix}_${self.crypto.randomUUID()}`;
}

export function attachDragEvents(
  element: HTMLElement,
  callbacks: Record<string, CallBack>
) {
  element.addEventListener('dragover', callbacks.dragover);
  element.addEventListener('dragleave', callbacks.dragleave);
  element.addEventListener('drop', callbacks.drop);
}

export function attachDragEventToElement(
  element: HTMLElement,
  callback: CallBack
) {
  element.addEventListener('dragstart', callback);
  element.dataset.source = 'table';
}

/**
 * function that creates a dropped element based on the original element's markup.
 */
export function createDroppedElementFromHTML(htmlString: string): HTMLElement {
  // Parse the HTML string to create a DOM element.
  const parser = new DOMParser();
  const doc = parser.parseFromString(
    htmlString.replace(
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
      ''
    ),
    'text/html'
  );
  const newEl: Element | null = doc.body.firstElementChild;
  if (!(newEl instanceof HTMLElement)) return document.createElement('span');

  // Mark it as coming from the table.
  newEl.dataset.source = 'table';
  // Ensure the element is draggable.
  newEl.draggable = true;
  // Reattach the dragstart event listener.
  attachDragEventToElement(newEl, handleDragStart);

  return newEl;
}
